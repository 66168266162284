import classNames from 'classnames'
import React, { ReactElement, useState } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'

import { Input } from '../../types/input'
import EyeIcon from '../Icons/EyeIcon'
import {
  blockCustomInput,
  button,
  customInput,
  customInputError,
  customInputNormal,
  customInputNumber,
  customInputPassword,
  customInputSmall,
  label,
  labelError,
  wrapper,
} from './CustomInput.module.scss'

interface ICustomInputProps {
  input?: WrappedFieldInputProps
  meta?: WrappedFieldMetaProps
  placeholder?: string
  disabled?: boolean
  type?: Input
  value?: string
  required?: boolean
  label?: string
  isReserveLabelError?: boolean
  maxLength?: number
  size?: 'small' | 'large' | 'normal'
  max?: number
  min?: number
}

const CustomInput = (props: ICustomInputProps): ReactElement<ICustomInputProps> => {
  const { isReserveLabelError } = props
  const disabled = props.disabled || false
  let meta: string
  const [eyeOpen, setEyeOpen] = useState(false)

  if (props.meta && props.meta.touched && props.meta.error) {
    meta = props.meta.error
  }

  if (props.meta && props.meta.touched && props.meta.warning) {
    meta = props.meta.warning
  }

  const labelErrorClassName = classNames(label, {
    [labelError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
  })

  const inputClassName = classNames(customInput, {
    [customInputError]: props.meta && props.meta.touched && (props.meta.error || props.meta.warning),
    [customInputSmall]: props.size === 'small',
    [customInputNormal]: props.size === 'normal',
    [customInputPassword]: props.type === 'password',
    [customInputNumber]: props.type === 'number',
  })

  const clickEyeButtonHandler = () => {
    setEyeOpen((prev) => !prev)
  }

  return (
    <div className={blockCustomInput}>
      {props.label && <label className={label}>{props.label}</label>}
      <div className={wrapper}>
        <input
          maxLength={props.maxLength}
          {...props.input}
          placeholder={props.placeholder}
          className={inputClassName}
          disabled={disabled}
          autoComplete="off"
          type={eyeOpen ? 'text' : props.type}
          value={props.value ?? props.input.value}
          min={props.min}
          max={props.max}
        />
        {props.type === 'password' && (
          <button className={button} onClick={clickEyeButtonHandler} type="button">
            <EyeIcon state={eyeOpen ? 'normal' : 'crossed-out'} />
          </button>
        )}
      </div>

      {isReserveLabelError && <span className={labelErrorClassName}>{meta}&nbsp;</span>}
    </div>
  )
}

export default CustomInput
