import classNames from 'classnames'
import React, { ReactElement } from 'react'

import { eyeIcon, eyeIconCrossedOut, eyeIconNormal } from './EyeIcon.module.scss'

interface IEyeIconProps {
  state: 'normal' | 'crossed-out'
}

const EyeIcon = (props: IEyeIconProps): ReactElement => {
  return (
    <span
      className={classNames(eyeIcon, {
        [eyeIconNormal]: props.state === 'normal',
        [eyeIconCrossedOut]: props.state === 'crossed-out',
      })}
    ></span>
  )
}

export default EyeIcon
