import React, { ReactElement } from 'react'
import Loader from 'react-loader-spinner'

import { loader } from './LoaderSpinner.module.scss'

interface ILoaderSpinnerProps {
  color?: string
  width?: number
  height?: number
  type?:
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'MutatingDots'
    | 'None'
    | 'NotSpecified'
    | 'Oval'
    | 'Plane'
    | 'Puff'
    | 'RevolvingDot'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Triangle'
    | 'Watch'
}

const LoaderSpinner = (props: ILoaderSpinnerProps): ReactElement<ILoaderSpinnerProps> => {
  return (
    <div className={loader}>
      <Loader
        type={props.type ?? 'ThreeDots'}
        color={props.color ?? '#00BFFF'}
        height={props.height ?? 80}
        width={props.width ?? 80}
      />
    </div>
  )
}

export default LoaderSpinner
