// extracted by mini-css-extract-plugin
export var customInput = "CustomInput-module--custom-input--27BSQ";
export var label = "CustomInput-module--label--3daoL";
export var button = "CustomInput-module--button--307-a";
export var blockCustomInput = "CustomInput-module--block-custom-input--1QN9C";
export var customInputError = "CustomInput-module--custom-input-error--2cNG5";
export var customInputSmall = "CustomInput-module--custom-input-small--3DOa3";
export var customInputNormal = "CustomInput-module--custom-input-normal--3M1xt";
export var customInputPassword = "CustomInput-module--custom-input-password--2tUQ8";
export var customInputNumber = "CustomInput-module--custom-input-number--3CEPM";
export var wrapper = "CustomInput-module--wrapper--MBv4R";
export var labelError = "CustomInput-module--label-error--1YcFW";